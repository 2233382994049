import background from '../images/professionals.webp';

const Holistic = () => {
    return <div id="professionals" class="section" style={{'--image-src': `url(${background})`, '--image-overlay': '#c3bfbd80', '--image-opacity': '0.6', minHeight: '760px'}}>
      <div style={{maxWidth: '1100px'}}>
        <h2 style={{color: 'var(--primary-color)'}}>Karierremöglichkeit als ZMP/DH</h2>
        <h3 style={{color: '#d9d9d9'}}>der Schlüssel zum Erfolg.</h3>
        <p style={{color: '#ffffff'}}>Mit dem testbasiertem Gesundheitskonzept hast du als ZMP oder DH fantastische Karierremöglichkeiten. Sei Teil dieser spannenden Entwicklung in der ​Dentalhygiene! Lass uns gemeinsam daran arbeiten, das Wohlbefinden der Patienten zu ​steigern und die Zahnarztpraxis auf die nächste Stufe zu heben. Hier hast du die ​Möglichkeit dich als Expertin in der Praxis zu positioniren, finanziell zu profitieren und ​dich regelmäßig fortzubilden. Erfahre mehr über dieses Konzept und lass uns gemeinsam ​Großes erreichen!</p>
        <a href="/contact?topic=professionals" class="button">Zu​m Kontaktformular</a>
      </div>
    </div>;
  };
  
  export default Holistic;