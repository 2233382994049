import background from '../images/practice.webp';
import arrow from '../images/arrow.svg';

const Holistic = () => {
    return <div id="practice" class="section" style={{'--image-src': `url(${background})`, '--image-overlay': '#c3bfbd80', '--image-opacity': '0.8', minHeight: '760px'}}>
      <div style={{display: 'flex', flexDirection: 'column',  maxWidth: '1000px'}}>
        <h2 class="heading-m" style={{color: '#ffffff', margin: '0'}}>Ganzheitlich Behandeln</h2>
        <h3 class="heading-s" style={{color: '#737373'}}>in der Zahnarztpraxis.</h3>
        <div style={{display: 'flex', flexDirection: 'column', gap: '1rem', color: '#ffffff'}}>
          <p>Bei einer ganzheitlichen Dentalhygiene geht es darum, den ​Körper von Innen heraus zu stärken. Folgende Punkte ​sollten bei Patienten beachtet werden:</p>
          <div style={{display: 'flex'}}>
            <div style={{flexGrow: '1', flexBasis: '0', display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
              <img src={arrow} alt="pfeil" style={{display: 'block', maxWidth: '200px'}}></img>
            </div>
            <div style={{flexGrow: '1', flexBasis: '0', display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
              <ul style={{flexGrow: '1', flexBasis: '0', margin: '0'}}>
                <li>Antientzündliche Ernährung</li>
                <li>Vitmain D</li>
                <li>Omega-3</li>
                <li>Unterstützung Darmgesundheit</li>
                <li>Gesunder Lifestyle</li>
                <li>Natürliche Mundhygienemassnahmen</li>
              </ul>
            </div>
          </div>
          <a href="/contact?topic=practice" class="button" style={{alignSelf: 'start'}}>Auch bald in deiner Praxis?</a>
        </div>
      </div>
    </div>;
  };
  
  export default Holistic;