import background from '../images/aboutme.webp';
import portfolio from '../images/portfolio2.webp';

const AboutMe = () => {
    return <div id="aboutMe" class="section" style={{'--image-src': `url(${background})`, '--image-overlay': '#d9d9d980', '--image-opacity': '0.8', minHeight: '760px'}}>
      <div class="mobile-column" style={{display: 'flex', alignItems: 'stretch', gap: '2rem', maxWidth: '1100px'}}>
        <div class="bottom-left-half-border" style={{flexGrow: '1', flexBasis: '0', padding: '1rem'}}>
          <h2 class="heading-m" style={{color: '#ffffff', fontWeight: '400'}}>Kurz zu mir</h2>
          <img src={portfolio} alt="portfolio" style={{display: 'block', maxWidth: '100%', maxHeight: '100%'}} ref={ref => window.registerFlyIn(ref)}></img>
        </div>
        <div style={{flexGrow: '1', flexBasis: '0'}}>
          <h3 class="heading-s" style={{color: 'var(--primary-color)'}}>Beruflich</h3>
          <p style={{color: '#ffffff', textAlign: 'justify'}}>Ich bin Dentalhygienikerin & ganzheitliche Ernährungs- und Gesundheitsberaterin. Seit 2023 arbeite ich mit einem testbasiertem Gesundheitskonzept das perfekt in die Zahnarztpraxis passt. Mittlerweile habe ich ein Team aus ZMPs und DHs, die dieses Konzept ebenfalls integrieren. Zusätzlich coache Zahnarztpraxen, ZMPs und Dhs zu ganzheitlichen Themen in der Zahnarztpraxis.</p>
          <h3 class="heading-s" style={{color: 'var(--primary-color)'}}>Privat</h3>
          <p style={{color: '#ffffff', textAlign: 'justify'}}>Ich wohne in Bad Abbach in der Nähe von Regensburg zusammen mit meinem Mann und meinen zwei Töchtern.</p>
          <p style={{color: '#ffffff', textAlign: 'justify'}}>Auf den folgenden Seiten erfährst du weshalb der ganzheitliche Ansatz in der Zahnarztpraxis wichtig ist und herausfinden ob auch du es in der Praxis etablieren möchtest.</p>
        </div>
      </div>
    </div>
  };
  
  export default AboutMe;