import logo from '../images/logo.webp';
import instagram from '../images/instagram.png';
import mail from '../images/mail.png';
import linkedin from '../images/linkedin.png';

const AboutMe = () => {
    return <div id="info" class="section" style={{backgroundColor: '#c3bfbd', minHeight: '760px'}}>
      <div class="mobile-column" style={{display: 'flex', alignItems: 'stretch', gap: '2rem', maxWidth: '1100px'}}>
        <img src={logo} alt="logo" style={{display: 'block', maxWidth: '100%', maxHeight: '100%'}}></img>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <h3 style={{color: '#ffffff', fontSize: '1.5rem'}}>Mundum Gesund<br/>Dentalhygiene Elena Giesbrecht</h3>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'end', flexGrow: '1', gap: '2rem'}}>
            <a href="mailto:elena@dentalhygiene-eg.de" class="social"><img src={mail} alt="mail"></img>elena@dentalhygiene-eg.de</a>  
            <a href="https://instagram.com/mundum_gesund" target="_blank" class="social"><img src={instagram} alt="instagram"></img>@mundum_gesund</a>
            <a href="https://linkedin.com/in/mundum-gesund-elena-giesbrecht-9087a7268/" target="_blank" class="social"><img src={linkedin} alt="linkedin"></img>mundum-gesund</a>
          </div>
        </div>
      </div>
    </div>
  };
  
  export default AboutMe;