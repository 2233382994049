const Contact = () => {
  return <div>
    <h1>Kontakt</h1>
    <div class="form">
      <label for="firstName">Vorname</label>
      <input id="firstName"></input>
      <label for="lastName">Nachname</label>
      <input id="lastName"></input>
      <label for="sender">E-Mail</label>
      <input id="sender"></input>
      <label for="topic">Thema</label>
      <select id="topic" ref={ref => loadTopicFromQuryParams(ref)}>
        <option value="general">Allgemein</option>
        <option value="practice">Terminanfrage Praxis</option>
        <option value="professionals">Terminanfrage ZMP/DH</option>
        <option value="else">Sonstiges</option>
      </select >
      <label for="message">Nachricht</label>
      <textarea id="message"></textarea>
      <button onClick={e => submit()}>Absenden</button>
    </div>
  </div>;
};
  
export default Contact;

function loadTopicFromQuryParams(element) {
  if (element) {
    element.value = new URL(document.location.toString()).searchParams?.get('topic') || 'general';
  }
}

function submit() {
  fetch('http://v29525.1blu.de/backend/index.php/mail/send?param=test').then(response => response.json()).then(data => console.log(data));
}