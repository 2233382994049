import background from '../images/concept.webp';
import test from '../images/test.webp';
import consulting from '../images/consulting.webp';
import analysis from '../images/analysis.webp';

const Holistic = () => {
    return <div id="concept" class="section" style={{'--image-src': `url(${background})`, '--image-overlay': '#ffffff80', '--image-opacity': '0.8', minHeight: '760px'}}>
      <h2 class="heading-m" style={{color: '#a0a0a3', margin: '0', textTransform: 'uppercase'}}>Testbasiertes Gesundheitskonzept</h2>
      <h3>einzigartig - innovativ - wissenschaftlich basiert - wie gemacht für die Zahnarztpraxis</h3>
      <div class="mobile-column" style={{maxWidth: '1100px', display: 'flex', gap: '2rem', marginTop: '100px'}}>
        <div class="concept-description">
          <img src={test} alt="test" ref={ref => window.registerFlyIn(ref)}></img>
          <h4 class="heading-s">Trockenbluttest</h4>
          <p>Eine einfache Methode zur Blutentnahme ​und Analyse, bei der nur 2 Tropfen Blut auf ​ein spezielles Filterpapier oder eine andere ​absorbierende Fläche aufgetragen und ​anschließend getrocknet wird. Diese Technik ​hat in den letzten Jahren an Bedeutung ​gewonnen, insbesondere in der Diagnostik ​und der medizinischen Forschung.</p>
        </div>
        <div class="concept-description">
          <img src={consulting} alt="beratung" ref={ref => window.registerFlyIn(ref)}></img>
          <h4 class="heading-s">Individuelle Beratung</h4>
          <p>Jeder Patient ist einzigartig. Dieser ​Ansatz ermöglicht es, gezielt auf die ​Bedürfnisse und Herausforderungen ​jedes Einzelnen einzugehen. Das Konzept ​wird im besten Fall mit einem ​Ernährungsprotokoll und einer ​ganzheitlichen Anamnese ergänzt.</p>
        </div>
        <div class="concept-description">
          <img src={analysis} alt="analyse" ref={ref => window.registerFlyIn(ref)}></img>
          <h4 class="heading-s">Detalierte Analyse</h4>
          <p>Präzise Defizitermittlung: Durch innovative ​Tests können wir Schwächen und ​Verbesserungspotenziale frühzeitig ​erkennen und gezielt angehen. Langfristige ​Mundgesundheit: Mit einer ​maßgeschneiderten Strategie fördern Sie ​nicht nur die Gesundheit Ihrer Patienten, ​sondern auch deren Zufriedenheit und ​Loyalität.</p>
        </div>
      </div>
    </div>;
  };
  
  export default Holistic;