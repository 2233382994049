import { Outlet, Link } from "react-router-dom";
import logoSmall from '../images/logo-small.png';

const Layout = () => {
    return (
      <>
        <header>
            <img src={logoSmall} alt="logo"></img>
            <a href="/" class="heading-s">Mundum Gesund Elena Giesbrecht</a>
        </header>
        <main>
            <Outlet />
        </main>
        <footer>
            <nav>
                <ul>
                    <li>
                        <Link to="/contact">Kontakt</Link>
                    </li>
                    <li>
                        <Link to="/legal-notice">Impressum</Link>
                    </li>
                    <li>
                        <Link to="/privacy-policy">Datenschutz</Link>
                    </li>
                </ul>
            </nav>
        </footer>
      </>
    )
  };

export default Layout;